<template>

  <button
    @click="buttonAction(field)"
    :disabled="field.disabled || false"
    :class="{ 'hover:bg-grey-darker' : !field.disabled, 'border border-red': field.error }"
    class="relative bg-grey-dark text-grey-lightest shadow-md px-4 py-2 leading-loose"
  >
    <slot>{{ field.label }}</slot>

    <!-- Mask -->
    <div v-if="field.mask" class="absolute inset-0">

      <!-- Background -->
      <div style="opacity:0.9" class="absolute inset-0 z-10 bg-telkom-blue-lightest"></div>

      <!-- Text -->
      <div class="absolute inset-0 z-20 text-telkom-blue-dark flex items-center justify-center">
        <div class="px-4 py-2">{{ field.maskText || 'Busy...' }}</div>
      </div>

    </div>

  </button>

</template>

<script>

import { mapActions } from 'vuex'

import 'dwnld-attr-polyfill'

export default {

  name: 'AclButton',

  props: {

    field: { type: Object }

  },

  methods: {

    ...mapActions(['componentAction']),

    buttonAction (field) {

      let action = field.action || {};

      if (action.action) {

        this.$set(field, 'disabled', true);
        this.$set(field, 'mask', true);
        this.$set(field, 'message', false);
        this.$set(field, 'error', false);

        /*
        |--------------------------------------------------------------------------
        | FUTURE USE:
        |--------------------------------------------------------------------------
        |
        | Send the form fields with the action, so acl can use them.
        |
        |--------------------------------------------------------------------------
        */
        // Get form fields as a plain array (ie: not an observable)
        // let fields = JSON.parse(JSON.stringify(this.data.form.fields));
        // Append form fields to the action (could be super useful for the acl)
        // action.form = { fields: fields || [] };

        this.componentAction(action)

          .then((resp) => {

            let data = resp.data || false;

            // let _buffer = data['buffer'] || '';
            let _return = data['return'] || {};

            if (data) {

              let message = _return.message || false;
              let error   = _return.error   || false;

              if (typeof message === 'string') { this.$set(field, 'message', message); }
              if (typeof error   === 'string') { this.$set(field, 'error',   error); }

              let pdf_base64_string   = _return['pdf_base64_string']   || false;
              let pdf_base64_filename = _return['pdf_base64_filename'] || 'download.php';
              if (pdf_base64_string) {
                var a = document.createElement('a');
                a.download = pdf_base64_filename;
                a.href = 'data:application/pdf;base64,' + pdf_base64_string;
                a.click();
              }

            } else {

              this.$set(field, 'error', 'An error occurred');
            }

            this.$set(field, 'disabled', false);
            this.$set(field, 'mask', false);

          });

      }

    }

  }

}
</script>
